body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
}

h5 {
  font-size: 1.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.brand-logo {
  height: 30px;
  margin-right: 0.5rem;
}

.footer-copyright {
	font-size: 10px;
}

footer.page-footer {
  z-index: 1;
  position: fixed;
  left: 0;
  right: 0;
}

/* LinkSocMedia component styling */

.socCardBody {
  padding-right: 2.5rem;
}
.socMediaRow {
  justify-content: space-between;
}
