.signup-container {
  margin-top: -50px;
}

ol.progtrckr {
    list-style-type: none;
    padding: 0;
  }
  
  ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    cursor: pointer;
  }
  
  ol.progtrckr li span {
    padding: 0 1.5rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }
  
  ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver;
  }
  
  ol.progtrckr li.progtrckr-doing {
    color: black;
    border-bottom: 4px solid #CCCCCC;
  }
  
  ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid #5cb85c;
  }
  
  ol.progtrckr li:after {
    content: "\A0\A0";
  }
  
  ol.progtrckr li:before {
    position: relative;
    bottom: -3.95rem;
    float: left;
    left: 50%;
  }
  
  ol.progtrckr li.progtrckr-todo:before {
    content: "\2022";
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  
  ol.progtrckr li.progtrckr-todo:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #CCCCCC;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  
  
  ol.progtrckr li.progtrckr-doing:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #5cb85c;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  
  ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
  }

  /* ol.progtrckr li:last-child.progtrckr-doing:before {
    content: "\2713";
    color: white;
    background-color: #5cb85c;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  ol.progtrckr li:last-child.progtrckr-doing {
    color: black;
    border-bottom: 4px solid #5cb85c;
  } */

  .step-progress {
    margin: 20px;
  }
  .step {
    min-height: 450px;
    max-width: 800px;
    padding: 10px;
    margin: auto;
    font-size: 1.2rem;
  }
  .step label {
    color: #000;
  }

  .pull-right {
    float: right;
  }
  .pull-left {
    float: left;
  }

  .footer-buttons {
    max-width: 800px;
    margin: auto;
  }

  .form-check {
    text-align: center;
    line-height: 22px;
    font-size: 14px;
  }

  .step-progress {
    text-align: center;
  }

  