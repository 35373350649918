body {
	background:#ededed;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover{
  color: #fff;
}

.nav-link.active{
  background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5{
  margin-bottom: 8rem !important;
}

.coming-soon {
  background-image: url(../assets/multi-people.png); 
  /* filter: grayscale(50%); */
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.login-banner {
  background-image: url(../assets/login-banner.png); 
  /* filter: grayscale(50%); */
  background-position: center;
  background-size: cover;
  /* position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; */
}

.coming-soon .free-bird {
  margin-top: 0px;
}

.aboardme-logo {
  background-image: url(../assets/aboardme-logo-trans.png); 
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
	width: auto;
	height: 112px;
}

.coming-soon-text {
	font-family: 'Sniglet', cursive;
	font-weight: 800;
	margin: 20px 0;
	color: dimgray;
	text-align: center;
}

.hire {
  padding: 30px;
  position: absolute;
  bottom: 45px;
  width: 100%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.hire h3 {
	font-size: 36px;
	font-weight: 800;
	line-height: 36px;
}

.hire h3 span {
	color: #008374;
}

.hire h4 {
	font-size: 24px;
	letter-spacing: 3px;
	color: #008374;
}

.hire hr {
	width: 250px;
	border-width: 1.5px
}