.dashboard {
    font-size: 10px;
}

.dashboard .row {
    margin: 0;
}

.section-title {
    padding-left: 1rem;
    font-weight: 600;
}