body {
	background:#ededed;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover{
  color: #fff;
}

.nav-link.active{
  background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5{
  margin-bottom: 8rem !important;
}

.coming-soon {
  background-image: url(/static/media/multi-people.6e836733.png); 
  /* filter: grayscale(50%); */
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.login-banner {
  background-image: url(/static/media/login-banner.4347b705.png); 
  /* filter: grayscale(50%); */
  background-position: center;
  background-size: cover;
  /* position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; */
}

.coming-soon .free-bird {
  margin-top: 0px;
}

.aboardme-logo {
  background-image: url(/static/media/aboardme-logo-trans.d6966b86.png); 
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
	width: auto;
	height: 112px;
}

.coming-soon-text {
	font-family: 'Sniglet', cursive;
	font-weight: 800;
	margin: 20px 0;
	color: dimgray;
	text-align: center;
}

.hire {
  padding: 30px;
  position: absolute;
  bottom: 45px;
  width: 100%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.hire h3 {
	font-size: 36px;
	font-weight: 800;
	line-height: 36px;
}

.hire h3 span {
	color: #008374;
}

.hire h4 {
	font-size: 24px;
	letter-spacing: 3px;
	color: #008374;
}

.hire hr {
	width: 250px;
	border-width: 1.5px
}

body {
	background-color: #fff;
}

.react-phone-number-input__row
{
	/* This is done to stretch the contents of this component */
	display     : flex;
 align-items : center;
    font-size: 20px;
}

.react-phone-number-input__phone
{
	/* The phone number input stretches to fill all empty space */
	flex : 1 1;

	/* The phone number input should shrink
	   to make room for the extension input */
	min-width : 0;
}

.react-phone-number-input__icon
{
	/* The flag icon size is 4x3 hence the exact `width` and `height` values */
	width      : 1.24em;
	height     : 0.93em;

	/* `1px` is still too much for a "retina" screen but there's no way in CSS to specify "hairline" border width. */
	border     : 1px solid rgba(0, 0, 0, 0.5);

	/* Makes sure `width`x`height` is exactly `4x3` and `border` width is not included in it */
	box-sizing : content-box;
}

.react-phone-number-input__icon--international
{
	/* The international icon size is square */
	/* and also has no border hence the `2 * 1px` `width` and `height` compensation */
	width  : calc(0.93em + 2px);
	height : calc(0.93em + 2px);

	/* The international icon size is square hence the exact `padding` value */
	/* for precise alignment with `4x3` sized country flags. */
	padding-left  : 0.155em;
	padding-right : 0.155em;

	border : none;
}

.react-phone-number-input__error
{
	margin-left : calc(1.24em + 2px + 0.3em + 0.35em + 0.5em);
	margin-top  : calc(0.3rem);
	color       : #D30F00;
}

.react-phone-number-input__icon-image
{
	max-width  : 100%;
    max-height : 100%;
    margin-top: -15px;
}

/* Removes `<input type="number"/>` up/down arrows in Webkit browsers. */
.react-phone-number-input__ext-input::-webkit-inner-spin-button,
.react-phone-number-input__ext-input::-webkit-outer-spin-button
{
	margin             : 0 !important;
	-webkit-appearance : none !important;
	-moz-appearance    : textfield !important;
}

.react-phone-number-input__ext-input
{
	width : 3em;
}

.react-phone-number-input__ext
{
	white-space: nowrap;
}

.react-phone-number-input__ext,
.react-phone-number-input__ext-input
{
	margin-left : 0.5em;
}

/* Styling native country `<select/>`. */

.react-phone-number-input__country--native
{
	position     : relative;
	align-self   : stretch;
	display      : flex;
	align-items  : center;
	margin-right : 0.5em;
}

.react-phone-number-input__country-select
{
	position : absolute;
	top      : 0;
	left     : 0;
	height   : 100%;
	width    : 100%;
	z-index  : 1;
	border   : 0;
	opacity  : 0;
	cursor   : pointer;
}

.react-phone-number-input__country-select-arrow
{
	display            : block;
	content            : '';
	width              : 0;
	height             : 0;
	margin-bottom      : 0.1em;
	margin-top         : 0.3em;
	margin-left        : 0.3em;
	border-width       : 0.35em 0.2em 0 0.2em;
	border-style       : solid;
	border-left-color  : transparent;
	border-right-color : transparent;
	color              : #B8BDC4;
	opacity            : 0.7;
	-webkit-transition         : color 0.1s;
	transition         : color 0.1s;
}

/* Something from stackoverflow. */
.react-phone-number-input__country-select-divider
{
	font-size  : 1px;
	background : black;
}

.react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow,
.react-phone-number-input__country.rrui__select--focus .rrui__select__arrow
{
	color : #C5D2E0;
}

/* Styling phone number input */

.react-phone-number-input__input
{
	height        : calc(0.3rem * 6);
	outline       : none;
	border-radius : 0;
	padding       : 0;
	-webkit-appearance    : none;
	   -moz-appearance    : none;
	        appearance    : none;
	border        : none;
	border-bottom : 1px solid #C5D2E0;
	-webkit-transition    : border 0.1s;
	transition    : border 0.1s;
	font-size     : 1.8rem;
	text-align: center;
}

.react-phone-number-input__input:focus
{
	border-color : #03B2CB;
}

.react-phone-number-input__input--disabled
{
	cursor : default;
}

.react-phone-number-input__input--invalid,
.react-phone-number-input__input--invalid:focus
{
	border-color : #EB2010;
}

/* Overrides Chrome autofill yellow background color */
.react-phone-number-input__input:-webkit-autofill
{
	box-shadow : 0 0 0 1000px white inset;
}

/* A small fix for `react-responsive-ui` */
.react-phone-number-input__country .rrui__select__button
{
	border-bottom : none;
}

.login-with-phone {
	font-size: 1.4rem;
}

.recaptcha {
	height: 0px;
	width: 0px;
}
.login-with-phone .hidden {
	display: none;
}

.confirm-code {
	margin-top: -9rem;
}
.verification-code {
	font-size: 1.8rem !important;
	text-align: center;
}
@media only screen and (max-width: 600px) {
	.ab-logo {
	  width: 20rem;
	}
  }
.signup-container {
  margin-top: -50px;
}

ol.progtrckr {
    list-style-type: none;
    padding: 0;
  }
  
  ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    cursor: pointer;
  }
  
  ol.progtrckr li span {
    padding: 0 1.5rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }
  
  ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver;
  }
  
  ol.progtrckr li.progtrckr-doing {
    color: black;
    border-bottom: 4px solid #CCCCCC;
  }
  
  ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid #5cb85c;
  }
  
  ol.progtrckr li:after {
    content: "\A0\A0";
  }
  
  ol.progtrckr li:before {
    position: relative;
    bottom: -3.95rem;
    float: left;
    left: 50%;
  }
  
  ol.progtrckr li.progtrckr-todo:before {
    content: "\2022";
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  
  ol.progtrckr li.progtrckr-todo:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #CCCCCC;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  
  
  ol.progtrckr li.progtrckr-doing:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #5cb85c;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  
  ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
  }

  /* ol.progtrckr li:last-child.progtrckr-doing:before {
    content: "\2713";
    color: white;
    background-color: #5cb85c;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  ol.progtrckr li:last-child.progtrckr-doing {
    color: black;
    border-bottom: 4px solid #5cb85c;
  } */

  .step-progress {
    margin: 20px;
  }
  .step {
    min-height: 450px;
    max-width: 800px;
    padding: 10px;
    margin: auto;
    font-size: 1.2rem;
  }
  .step label {
    color: #000;
  }

  .pull-right {
    float: right;
  }
  .pull-left {
    float: left;
  }

  .footer-buttons {
    max-width: 800px;
    margin: auto;
  }

  .form-check {
    text-align: center;
    line-height: 22px;
    font-size: 14px;
  }

  .step-progress {
    text-align: center;
  }

  
.avatar {
    height: 10rem;
    width: 10rem;
    border-radius: 5rem !important;
    cursor: pointer;
}

.avatar-holder {
    position: relative;
}

.file-input {
    display: none;
}
.img-thumbnail {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    text-align: center;
    margin: 10px auto;
}

.user-cards {
  font-size: 1.2em;
} 

.user-cards .title {
  font-size: 1.2rem;
}

.user-cards .title, .user-cards .card-body {
  text-align: center;
  padding-top: 0;
}

.user-cards li.ist-inline-item, .user-cards .list-inline-item:not(:last-child) {
  margin-right: 0;
}

/* mixin for multiline */
.block-with-text {
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 6em;
    min-height: 6em;
    text-align: justify;
    margin-right: 0em;
    padding-right: 1em;
  }
  .block-with-text:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .block-with-text:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
.dashboard {
    font-size: 10px;
}

.dashboard .row {
    margin: 0;
}

.section-title {
    padding-left: 1rem;
    font-weight: 600;
}
* {
    box-sizing: border-box;
  }
  
  body {
    padding: 0;
    margin: 0;
  }
  
  #notfound {
    position: relative;
    height: 70vh;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
  }
  
  .notfound .notfound-404 {
    position: relative;
    height: 200px;
    margin: 0px auto 20px;
    z-index: -1;
  }
  
  .notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 236px;
    font-weight: 200;
    margin: 0px;
    color: #211b19;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .notfound .notfound-404 h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    color: #211b19;
    background: #fff;
    padding: 10px 5px;
    margin: auto;
    display: inline-block;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
  }
  
  .notfound a {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding: 13px 23px;
    background: #ff6300;
    font-size: 18px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
  }
  
  .notfound a:hover {
    color: #ff6300;
    background: #211b19;
  }
  
  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 h1 {
      font-size: 148px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
      height: 148px;
      margin: 0px auto 10px;
    }
    .notfound .notfound-404 h1 {
      font-size: 86px;
    }
    .notfound .notfound-404 h2 {
      font-size: 16px;
    }
    .notfound a {
      padding: 7px 15px;
      font-size: 14px;
    }
  }
  



.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar-nav {
  font-size: 1rem;
}

.hide {
  display: none;
}

.main-container {
  padding: 5rem 0;
}
.btn-fb {
    background-color: #3b5998!important;
    color: #fff!important
}

.btn-fb:hover {
    background-color: #4264aa
}

.btn-fb.focus,.btn-fb:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-fb.active,.btn-fb:active,.btn-fb:focus {
    background-color: #1e2e4f
}

.btn-fb.dropdown-toggle {
    background-color: #3b5998!important
}

.btn-fb.dropdown-toggle:focus,.btn-fb.dropdown-toggle:hover {
    background-color: #4264aa!important
}

.btn-fb:not([disabled]):not(.disabled).active,.btn-fb:not([disabled]):not(.disabled):active,.show>.btn-fb.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #1e2e4f!important
}

.btn-fb:not([disabled]):not(.disabled).active:focus,.btn-fb:not([disabled]):not(.disabled):active:focus,.show>.btn-fb.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.fb-ic {
    color: #3b5998!important
}

.fb-ic:focus,.fb-ic:hover {
    color: #3b5998
}

.btn-tw {
    background-color: #55acee!important;
    color: #fff!important
}

.btn-tw:hover {
    background-color: #6cb7f0
}

.btn-tw.focus,.btn-tw:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-tw.active,.btn-tw:active,.btn-tw:focus {
    background-color: #147bc9
}

.btn-tw.dropdown-toggle {
    background-color: #55acee!important
}

.btn-tw.dropdown-toggle:focus,.btn-tw.dropdown-toggle:hover {
    background-color: #6cb7f0!important
}

.btn-tw:not([disabled]):not(.disabled).active,.btn-tw:not([disabled]):not(.disabled):active,.show>.btn-tw.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #147bc9!important
}

.btn-tw:not([disabled]):not(.disabled).active:focus,.btn-tw:not([disabled]):not(.disabled):active:focus,.show>.btn-tw.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.tw-ic {
    color: #55acee!important
}

.tw-ic:focus,.tw-ic:hover {
    color: #55acee
}

.btn-gplus {
    background-color: #dd4b39!important;
    color: #fff!important
}

.btn-gplus:hover {
    background-color: #e15f4f
}

.btn-gplus.focus,.btn-gplus:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-gplus.active,.btn-gplus:active,.btn-gplus:focus {
    background-color: #96271a
}

.btn-gplus.dropdown-toggle {
    background-color: #dd4b39!important
}

.btn-gplus.dropdown-toggle:focus,.btn-gplus.dropdown-toggle:hover {
    background-color: #e15f4f!important
}

.btn-gplus:not([disabled]):not(.disabled).active,.btn-gplus:not([disabled]):not(.disabled):active,.show>.btn-gplus.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #96271a!important
}

.btn-gplus:not([disabled]):not(.disabled).active:focus,.btn-gplus:not([disabled]):not(.disabled):active:focus,.show>.btn-gplus.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.gplus-ic {
    color: #dd4b39!important
}

.gplus-ic:focus,.gplus-ic:hover {
    color: #dd4b39
}

.btn-yt {
    background-color: #ed302f!important;
    color: #fff!important
}

.btn-yt:hover {
    background-color: #ef4746
}

.btn-yt.focus,.btn-yt:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-yt.active,.btn-yt:active,.btn-yt:focus {
    background-color: #a80f0e
}

.btn-yt.dropdown-toggle {
    background-color: #ed302f!important
}

.btn-yt.dropdown-toggle:focus,.btn-yt.dropdown-toggle:hover {
    background-color: #ef4746!important
}

.btn-yt:not([disabled]):not(.disabled).active,.btn-yt:not([disabled]):not(.disabled):active,.show>.btn-yt.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #a80f0e!important
}

.btn-yt:not([disabled]):not(.disabled).active:focus,.btn-yt:not([disabled]):not(.disabled):active:focus,.show>.btn-yt.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.yt-ic {
    color: #ed302f!important
}

.yt-ic:focus,.yt-ic:hover {
    color: #ed302f
}

.btn-li {
    background-color: #0082ca!important;
    color: #fff!important
}

.btn-li:hover {
    background-color: #0092e4
}

.btn-li.focus,.btn-li:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-li.active,.btn-li:active,.btn-li:focus {
    background-color: #004064
}

.btn-li.dropdown-toggle {
    background-color: #0082ca!important
}

.btn-li.dropdown-toggle:focus,.btn-li.dropdown-toggle:hover {
    background-color: #0092e4!important
}

.btn-li:not([disabled]):not(.disabled).active,.btn-li:not([disabled]):not(.disabled):active,.show>.btn-li.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #004064!important
}

.btn-li:not([disabled]):not(.disabled).active:focus,.btn-li:not([disabled]):not(.disabled):active:focus,.show>.btn-li.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.li-ic {
    color: #0082ca!important
}

.li-ic:focus,.li-ic:hover {
    color: #0082ca
}

.btn-pin {
    background-color: #c61118!important;
    color: #fff!important
}

.btn-pin:hover {
    background-color: #dd131b
}

.btn-pin.focus,.btn-pin:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-pin.active,.btn-pin:active,.btn-pin:focus {
    background-color: #68090d
}

.btn-pin.dropdown-toggle {
    background-color: #c61118!important
}

.btn-pin.dropdown-toggle:focus,.btn-pin.dropdown-toggle:hover {
    background-color: #dd131b!important
}

.btn-pin:not([disabled]):not(.disabled).active,.btn-pin:not([disabled]):not(.disabled):active,.show>.btn-pin.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #68090d!important
}

.btn-pin:not([disabled]):not(.disabled).active:focus,.btn-pin:not([disabled]):not(.disabled):active:focus,.show>.btn-pin.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.pin-ic {
    color: #c61118!important
}

.pin-ic:focus,.pin-ic:hover {
    color: #c61118
}

.btn-ins {
    background-color: #2e5e86!important;
    color: #fff!important
}

.btn-ins:hover {
    background-color: #356b99
}

.btn-ins.focus,.btn-ins:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-ins.active,.btn-ins:active,.btn-ins:focus {
    background-color: #14293a
}

.btn-ins.dropdown-toggle {
    background-color: #2e5e86!important
}

.btn-ins.dropdown-toggle:focus,.btn-ins.dropdown-toggle:hover {
    background-color: #356b99!important
}

.btn-ins:not([disabled]):not(.disabled).active,.btn-ins:not([disabled]):not(.disabled):active,.show>.btn-ins.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #14293a!important
}

.btn-ins:not([disabled]):not(.disabled).active:focus,.btn-ins:not([disabled]):not(.disabled):active:focus,.show>.btn-ins.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.ins-ic {
    color: #2e5e86!important
}

.ins-ic:focus,.ins-ic:hover {
    color: #2e5e86
}

.btn-git {
    background-color: #333!important;
    color: #fff!important
}

.btn-git:hover {
    background-color: #404040
}

.btn-git.focus,.btn-git:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-git.active,.btn-git:active,.btn-git:focus {
    background-color: #000
}

.btn-git.dropdown-toggle {
    background-color: #333!important
}

.btn-git.dropdown-toggle:focus,.btn-git.dropdown-toggle:hover {
    background-color: #404040!important
}

.btn-git:not([disabled]):not(.disabled).active,.btn-git:not([disabled]):not(.disabled):active,.show>.btn-git.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #000!important
}

.btn-git:not([disabled]):not(.disabled).active:focus,.btn-git:not([disabled]):not(.disabled):active:focus,.show>.btn-git.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.git-ic {
    color: #333!important
}

.git-ic:focus,.git-ic:hover {
    color: #333
}

.btn-comm {
    background-color: #30cfc0!important;
    color: #fff!important
}

.btn-comm:hover {
    background-color: #45d4c6
}

.btn-comm.focus,.btn-comm:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-comm.active,.btn-comm:active,.btn-comm:focus {
    background-color: #1d7c73
}

.btn-comm.dropdown-toggle {
    background-color: #30cfc0!important
}

.btn-comm.dropdown-toggle:focus,.btn-comm.dropdown-toggle:hover {
    background-color: #45d4c6!important
}

.btn-comm:not([disabled]):not(.disabled).active,.btn-comm:not([disabled]):not(.disabled):active,.show>.btn-comm.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #1d7c73!important
}

.btn-comm:not([disabled]):not(.disabled).active:focus,.btn-comm:not([disabled]):not(.disabled):active:focus,.show>.btn-comm.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.comm-ic {
    color: #30cfc0!important
}

.comm-ic:focus,.comm-ic:hover {
    color: #30cfc0
}

.btn-vk {
    background-color: #4c75a3!important;
    color: #fff!important
}

.btn-vk:hover {
    background-color: #5882b1
}

.btn-vk.focus,.btn-vk:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-vk.active,.btn-vk:active,.btn-vk:focus {
    background-color: #2c435d
}

.btn-vk.dropdown-toggle {
    background-color: #4c75a3!important
}

.btn-vk.dropdown-toggle:focus,.btn-vk.dropdown-toggle:hover {
    background-color: #5882b1!important
}

.btn-vk:not([disabled]):not(.disabled).active,.btn-vk:not([disabled]):not(.disabled):active,.show>.btn-vk.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #2c435d!important
}

.btn-vk:not([disabled]):not(.disabled).active:focus,.btn-vk:not([disabled]):not(.disabled):active:focus,.show>.btn-vk.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.vk-ic {
    color: #4c75a3!important
}

.vk-ic:focus,.vk-ic:hover {
    color: #4c75a3
}

.btn-dribbble {
    background-color: #ec4a89!important;
    color: #fff!important
}

.btn-dribbble:hover {
    background-color: #ee6198
}

.btn-dribbble.focus,.btn-dribbble:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-dribbble.active,.btn-dribbble:active,.btn-dribbble:focus {
    background-color: #bc1455
}

.btn-dribbble.dropdown-toggle {
    background-color: #ec4a89!important
}

.btn-dribbble.dropdown-toggle:focus,.btn-dribbble.dropdown-toggle:hover {
    background-color: #ee6198!important
}

.btn-dribbble:not([disabled]):not(.disabled).active,.btn-dribbble:not([disabled]):not(.disabled):active,.show>.btn-dribbble.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #bc1455!important
}

.btn-dribbble:not([disabled]):not(.disabled).active:focus,.btn-dribbble:not([disabled]):not(.disabled):active:focus,.show>.btn-dribbble.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.dribbble-ic {
    color: #ec4a89!important
}

.dribbble-ic:focus,.dribbble-ic:hover {
    color: #ec4a89
}

.btn-so {
    background-color: #ffac44!important;
    color: #fff!important
}

.btn-so:hover {
    background-color: #ffb75e
}

.btn-so.focus,.btn-so:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-so.active,.btn-so:active,.btn-so:focus {
    background-color: #dd7b00
}

.btn-so.dropdown-toggle {
    background-color: #ffac44!important
}

.btn-so.dropdown-toggle:focus,.btn-so.dropdown-toggle:hover {
    background-color: #ffb75e!important
}

.btn-so:not([disabled]):not(.disabled).active,.btn-so:not([disabled]):not(.disabled):active,.show>.btn-so.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #dd7b00!important
}

.btn-so:not([disabled]):not(.disabled).active:focus,.btn-so:not([disabled]):not(.disabled):active:focus,.show>.btn-so.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.so-ic {
    color: #ffac44!important
}

.so-ic:focus,.so-ic:hover {
    color: #ffac44
}

.btn-slack {
    background-color: #56b68b!important;
    color: #fff!important
}

.btn-slack:hover {
    background-color: #68be97
}

.btn-slack.focus,.btn-slack:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-slack.active,.btn-slack:active,.btn-slack:focus {
    background-color: #327456
}

.btn-slack.dropdown-toggle {
    background-color: #56b68b!important
}

.btn-slack.dropdown-toggle:focus,.btn-slack.dropdown-toggle:hover {
    background-color: #68be97!important
}

.btn-slack:not([disabled]):not(.disabled).active,.btn-slack:not([disabled]):not(.disabled):active,.show>.btn-slack.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #327456!important
}

.btn-slack:not([disabled]):not(.disabled).active:focus,.btn-slack:not([disabled]):not(.disabled):active:focus,.show>.btn-slack.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.slack-ic {
    color: #56b68b!important
}

.slack-ic:focus,.slack-ic:hover {
    color: #56b68b
}

.btn-email {
    background-color: #4b515d!important;
    color: #fff!important
}

.btn-email:hover {
    background-color: #565d6b
}

.btn-email.focus,.btn-email:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-email.active,.btn-email:active,.btn-email:focus {
    background-color: #1d2025
}

.btn-email.dropdown-toggle {
    background-color: #4b515d!important
}

.btn-email.dropdown-toggle:focus,.btn-email.dropdown-toggle:hover {
    background-color: #565d6b!important
}

.btn-email:not([disabled]):not(.disabled).active,.btn-email:not([disabled]):not(.disabled):active,.show>.btn-email.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #1d2025!important
}

.btn-email:not([disabled]):not(.disabled).active:focus,.btn-email:not([disabled]):not(.disabled):active:focus,.show>.btn-email.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.email-ic {
    color: #4b515d!important
}

.email-ic:focus,.email-ic:hover {
    color: #4b515d
}

.btn-reddit {
    background-color: #ff4500!important;
    color: #fff!important
}

.btn-reddit:hover {
    background-color: #ff581a
}

.btn-reddit.focus,.btn-reddit:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-reddit.active,.btn-reddit:active,.btn-reddit:focus {
    background-color: #992900
}

.btn-reddit.dropdown-toggle {
    background-color: #ff4500!important
}

.btn-reddit.dropdown-toggle:focus,.btn-reddit.dropdown-toggle:hover {
    background-color: #ff581a!important
}

.btn-reddit:not([disabled]):not(.disabled).active,.btn-reddit:not([disabled]):not(.disabled):active,.show>.btn-reddit.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #992900!important
}

.btn-reddit:not([disabled]):not(.disabled).active:focus,.btn-reddit:not([disabled]):not(.disabled):active:focus,.show>.btn-reddit.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.reddit-ic {
    color: #ff4500!important
}

.reddit-ic:focus,.reddit-ic:hover {
    color: #ff4500
}

.btn-twitch {
    background-color: #6441a4!important;
    color: #fff!important
}

.btn-twitch:hover {
    background-color: #6f48b6
}

.btn-twitch.focus,.btn-twitch:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-twitch.active,.btn-twitch:active,.btn-twitch:focus {
    background-color: #37245b
}

.btn-twitch.dropdown-toggle {
    background-color: #6441a4!important
}

.btn-twitch.dropdown-toggle:focus,.btn-twitch.dropdown-toggle:hover {
    background-color: #6f48b6!important
}

.btn-twitch:not([disabled]):not(.disabled).active,.btn-twitch:not([disabled]):not(.disabled):active,.show>.btn-twitch.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #37245b!important
}

.btn-twitch:not([disabled]):not(.disabled).active:focus,.btn-twitch:not([disabled]):not(.disabled):active:focus,.show>.btn-twitch.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.twitch-ic {
    color: #6441a4!important
}

.twitch-ic:focus,.twitch-ic:hover {
    color: #6441a4
}

.btn-discord {
    background-color: #7289da!important;
    color: #fff!important
}

.btn-discord:hover {
    background-color: #869adf
}

.btn-discord.focus,.btn-discord:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.btn-discord.active,.btn-discord:active,.btn-discord:focus {
    background-color: #304eb6
}

.btn-discord.dropdown-toggle {
    background-color: #7289da!important
}

.btn-discord.dropdown-toggle:focus,.btn-discord.dropdown-toggle:hover {
    background-color: #869adf!important
}

.btn-discord:not([disabled]):not(.disabled).active,.btn-discord:not([disabled]):not(.disabled):active,.show>.btn-discord.dropdown-toggle {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
    background-color: #304eb6!important
}

.btn-discord:not([disabled]):not(.disabled).active:focus,.btn-discord:not([disabled]):not(.disabled):active:focus,.show>.btn-discord.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)
}

.discord-ic {
    color: #7289da!important
}

.discord-ic:focus,.discord-ic:hover {
    color: #7289da
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
}

h5 {
  font-size: 1.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.brand-logo {
  height: 30px;
  margin-right: 0.5rem;
}

.footer-copyright {
	font-size: 10px;
}

footer.page-footer {
  z-index: 1;
  position: fixed;
  left: 0;
  right: 0;
}

/* LinkSocMedia component styling */

.socCardBody {
  padding-right: 2.5rem;
}
.socMediaRow {
  justify-content: space-between;
}

