.img-thumbnail {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    text-align: center;
    margin: 10px auto;
}

.user-cards {
  font-size: 1.2em;
} 

.user-cards .title {
  font-size: 1.2rem;
}

.user-cards .title, .user-cards .card-body {
  text-align: center;
  padding-top: 0;
}

.user-cards li.ist-inline-item, .user-cards .list-inline-item:not(:last-child) {
  margin-right: 0;
}

/* mixin for multiline */
.block-with-text {
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 6em;
    min-height: 6em;
    text-align: justify;
    margin-right: 0em;
    padding-right: 1em;
  }
  .block-with-text:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .block-with-text:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }