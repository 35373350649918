.avatar {
    height: 10rem;
    width: 10rem;
    border-radius: 5rem !important;
    cursor: pointer;
}

.avatar-holder {
    position: relative;
}

.file-input {
    display: none;
}